<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="funds"
          :search="search"
          title="Dana Bos"
          subtitle="Halaman untuk menambahkan Dana Bos"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :is-loading-button="isLoadingButton"
          :is-disabled-button="!filterValid"
          :total-pages.sync="totalPages"
          :filter-month="filterMonth"
          @filter-month="filterMonthHandler($event)"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @close-month="closeMonthPickerHandler($event)"
          @export="exportData"
          @live-search="searchHandler"
          @check-description="checkDescription"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Dana Bos': 'Edit Dana Bos'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="fund.title"
                label="Judul"
                :rules="[validation.required]"
                outlined
                dense
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="gPrice"
                label="Nominal Anggaran"
                :rules="[validation.required]"
                outlined
                dense
                type="text"
                onkeypress="return /[0-9]/i.test(event.key)"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="fPrice"
                label="Nominal Pengeluaran"
                :rules="[validation.required]"
                outlined
                dense
                type="text"
                onkeypress="return /[0-9]/i.test(event.key)"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-menu
              :close-on-content-click="true"
              nudge-right="0"
              nudge-top="20"
              max-width="100%"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fund.date"
                  label="Waktu Mulai"
                  :rules="[validation.required]"
                  readonly
                  clearable
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="autofillStartDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fund.date"
                :first-day-of-week="0"
                locale="id-id"
                :min="new Date().toISOString().substr(0, 10)"
                color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="fund.description"
                label="Deskripsi"
                :rules="[validation.required]"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
      <!-- <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="!formValid"
          @click="add()"
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
          :disabled="!formValid"
          :loading="isLoadingButton"
          @click="update()"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDialog = false"
        >
          Batal
        </v-btn>
      </template> -->
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :hidden-actions="false"
      header="Deskripsi Dana Bos"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p>{{ detail.description }}</p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Dana Bos"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import currencyToIntFormat from '@/utils/currency/currencyToIntFormat'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'SchoolFund',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      validation: {
        required,
        integerValidator,
      },
      detail: {},
      formValid: false,
      modalDescription: false,
      width: 600,
      school: [],
      school_uuid: null,
      page: 1,
      imageOverlay: false,
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: true,
      isLoadingTable: true,
      filterMonth: true,
      filterValid: false,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'schoolfund',
      serviceExport: 'exportschoolfund',
      fund: {
        title: '',
        description: '',
        school_uuid: '',
        total_spent: '',
        total_budget: '',
        date: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Judul', value: 'title' },
        { text: 'Total Anggaran', value: 'total_budget' },
        { text: 'Total Pengeluaran', value: 'total_spent' },
        { text: 'Tanggal', value: 'date' },
        { text: 'Deskripsi', value: 'description_fund' },
        { text: 'Aksi', value: 'actions' },
      ],
      funds: [],
      filterQuery: {
        search: '',
        month: '',
      },
      month: '',
    }
  },
  computed: {
    gPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.fund.total_budget)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.fund.total_budget = formatedPrice === 'NaN' ? null : formatedPrice

        return this.fund.total_budget
      },
    },
    fPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.fund.total_spent)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.fund.total_spent = formatedPrice === 'NaN' ? null : formatedPrice

        return this.fund.total_spent
      },
    },
  },
  watch: {
    fund: {
      handler() {
        const valid = []
        const requiredField = ['title', 'total_budget', 'total_spent', 'date']
        Object.entries(this.fund).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    filterQuery: {
      handler() {
        const valid = []
        const requiredField = ['month']
        Object.entries(this.filterQuery).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.filterValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listFund(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listFund()
  },
  methods: {
    async checkDescription(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.detail = data.data
      })
      this.modalDescription = true
    },
    resetForm() {
      this.fund.title = ''
      this.fund.description = ''
      this.fund.total_spent = ''
      this.fund.total_budget = ''
      this.fund.date = ''
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.fund = data.data
      })
      this.modalDialog = true
    },
    async listFund(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        school_uuid: this.school_uuid,
        month: this.filterQuery.month,
      }).then(({ data }) => {
        this.funds = data.data.map((fund, index) => ({
          ...fund,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async update() {
      this.isLoadingButton = true
      this.fund.total_budget = currencyToIntFormat(this.fund.total_budget)
      this.fund.total_spent = currencyToIntFormat(this.fund.total_spent)
      await this.$services.ApiServices.update(this.service, this.fund, this.fund.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listFund(this.filterQuery)
    },
    async add() {
      this.formValid = true
      this.isLoadingButton = true
      this.fund.school_uuid = this.school_uuid
      this.fund.total_budget = currencyToIntFormat(this.fund.total_budget)
      this.fund.total_spent = currencyToIntFormat(this.fund.total_spent)
      await this.$services.ApiServices.add(this.service, this.fund).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listFund(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    closeMonthPickerHandler(date) {
      if (date === null) {
        this.isDisabledButton = false
        this.filterQuery.month = ''
        this.listFund(this.filterQuery)
      }
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.fund.uuid = uuid
    },
    autofillStartDate() {
      this.fund.date = new Date().toISOString().substr(0, 10)
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.fund.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listFund(this.filterQuery)
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listFund(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    filterMonthHandler(date) {
      this.month = date
      this.filterQuery.month = date.slice(-2)
      this.listFund(this.filterQuery)
      if (date === null) {
        this.filterQuery.month = ''
        this.listFund(this.filterQuery)
      }
    },
    async exportData(params = {}) {
      this.isLoadingButton = true
      await this.$services.ExportParamsServices.export(this.serviceExport, this.school_uuid, {
        ...params,
        month: this.filterQuery.month,
      }).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Export school fund has successfully',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `school-fund-${this.month}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
